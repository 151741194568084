<template>
    <div class="buy-now-list-item clearfix">
        <router-link
            tag="div"
            :to="{
                name: 'page.buyNowDetail',
                params: {
                    companySlug: buyNowItem.companySlug,
                    itemId: buyNowItem.itemId,
                },
            }"
        >
            <img alt="buy-now lot" loading="lazy" :src="getImage(buyNowItem)" width="256" height="128" />
            <p v-html="buyNowItem.companyName"></p>
            <p
                v-html="
                    'Sofortkauf-Preis: ' +
                    currencyFormater(
                        buyNowItem.itemPrice,
                        buyNowItem.itemCurrency
                    )
                "
            ></p>
        </router-link>
    </div>
</template>

<script>
import { currencyTools } from '../components/mixins/currencyTools';

export default {
    name: 'BuyNowItemListItem',
    mixins: [currencyTools],
    methods: {
        getImage: function (buyNowItem) {
            return (
                'https://images.sixbid.com/buynowbox/' + buyNowItem.itemImageSm
            );
        },
    },
    props: {
        buyNowItem: {
            required: true,
            type: Object,
        },
    },
    mounted() {
        this.$root.$on('update:language', () => {
            this.$forceUpdate();
        });
    },
};
</script>
<style lang="scss">
.buy-now-list-item {
    cursor: pointer;
    text-align: center;
    color: $text-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: $pad-md;

    img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 170px;
    }

    p {
        margin: 5px 0;
    }
}
</style>
