var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"buy-now-list-item clearfix"},[_c('router-link',{attrs:{"tag":"div","to":{
            name: 'page.buyNowDetail',
            params: {
                companySlug: _vm.buyNowItem.companySlug,
                itemId: _vm.buyNowItem.itemId,
            },
        }}},[_c('img',{attrs:{"alt":"buy-now lot","loading":"lazy","src":_vm.getImage(_vm.buyNowItem),"width":"256","height":"128"}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.buyNowItem.companyName)}}),_c('p',{domProps:{"innerHTML":_vm._s(
                'Sofortkauf-Preis: ' +
                _vm.currencyFormater(
                    _vm.buyNowItem.itemPrice,
                    _vm.buyNowItem.itemCurrency
                )
            )}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }