import Auth from '../lib/Authorization';
import axios from 'axios';
import store from '../store/store';

const requestFactory = () => {
    let call;
    return (config = {}, axiosInstance, forceLang = false) => {
        // only cancels new call if config.url is equal to the call before
        if (call && config.url === call.source) {
            call.cancel('Only one unique request allowed. #cancel#');
        }

        call = axios.CancelToken.source();
        call.source = config.url;
        config.cancelToken = call.token;

        if (!config.params) {
            config.params = {};
        }

        if (!forceLang) {
            config.params.lang = store.getters['lang/getLang'];
        }

        return axiosInstance(config);
    };
};

const once = requestFactory();

const defaults = {
    methods: 'GET',
};

// GET

export const getDatashares = (object, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            params: { lang },
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/live-bidding/v1/datashare`,
        };

        return once(config, axiosInstance);
    });
};

// We do not pass "third_party" parameter. Default is "auex"
export const getLiveBiddingToken = (company_id, third_party, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            params: { company_id, third_party, lang },
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/live-bidding/v1/get-new-token`,
        };
        return once(config, axiosInstance, true);
    });
};

export const postDatashare = (object, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'POST',
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/live-bidding/v1/datashare/auex`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const deleteDatashare = (object, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'DELETE',
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/live-bidding/v1/datashare/auex`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getAuexRunningAuctions = () => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_AUEX_RUNNING_AUCTIONS}`,
        };
        return once(config, axiosInstance);
    });
};

export const getAuexAuctionLiveBiddingStatusCode = (urlBase, auctionId) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${urlBase}/v2/api/Ticker/GetSixbidAuktionsstatus?sixbidAuctionId=${auctionId}`,
        };
        return once(config, axiosInstance);
    });
};

export const getLotDetails = (
    { lotIds = [], companySlug, auctionId },
    lang,
    useArchiveLink = false
) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        return Promise.all(
            lotIds.map(lotId => {
                let url = `${process.env.VUE_APP_LOTS_API_URL}/${companySlug}/${auctionId}/${lotId}`;
                if (useArchiveLink) {
                    url = `${process.env.VUE_APP_LOTS_API_URL}/archive/${companySlug}/${auctionId}/${lotId}`;
                }
                const config = {
                    ...defaults,
                    url,
                    params: { lang },
                };
                return once(config, axiosInstance);
            })
        );
    });
};

export const getLots = ({ data, companySlug, auctionId }, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            params: { ...data, lang },
            url: `${process.env.VUE_APP_LOTS_API_URL}/`,
        };

        if (companySlug) {
            config.url = `${process.env.VUE_APP_LOTS_API_URL}/${companySlug}/`;
        }

        if (companySlug && auctionId) {
            config.url = `${process.env.VUE_APP_LOTS_API_URL}/${companySlug}/${auctionId}/`;
        }

        return once(config, axiosInstance);
    });
};

export const getSessions = (companySlug, auctionId, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            url: `${process.env.VUE_APP_AUCTIONS_SESSIONS_API_URL}/${companySlug}/${auctionId}`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getCompanyDetails = (companySlug, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            url: `${process.env.VUE_APP_COMPANIES_API_URL}/${companySlug}`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getCompanyObject = (idObj, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            url: `${process.env.VUE_APP_COMPANIES_API_URL}/info`,
            params: { lang, ...idObj },
        };
        return once(config, axiosInstance);
    });
};

export const getCompanies = (lang, limit) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            url: `${process.env.VUE_APP_COMPANIES_API_URL}`,
            params: { lang, limit },
        };
        return once(config, axiosInstance);
    });
};

export const getPartnerCompanies = lang => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            url: `${process.env.VUE_APP_COMPANIES_API_URL}/partnerlist`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getLotsCompanyList = (lang, term) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            url: `${process.env.VUE_APP_LOTS_API_URL}/companylist`,
            params: { lang, term },
        };
        return once(config, axiosInstance);
    });
};

export const getThisWeekInHistory = () => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_TWIH_API_URL}`,
            params: {},
        };
        return once(config, axiosInstance);
    });
};

export const getMyAddresses = lang => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/my-addresses`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getAuctionDetails = (data, companySlug, auctionId, lang, useArchiveLink = false) => {
    let url = `${process.env.VUE_APP_AUCTIONS_API_URL}/${companySlug}/${auctionId}`;
    if (useArchiveLink) {
        url = `${process.env.VUE_APP_AUCTIONS_API_URL}/archive/${companySlug}/${auctionId}`;
    }
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url,
            params: { ...data, lang },
        };
        return once(config, axiosInstance);
    });
};

export const getAuctions = (data, companySlug, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_AUCTIONS_API_URL}/`,
            params: { ...data, lang },
        };
        if (companySlug) {
            config.url = `${process.env.VUE_APP_AUCTIONS_API_URL}/${companySlug}`;
        }

        return once(config, axiosInstance);
    });
};

export const getAuctionTerms = (companySlug, auctionId, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_AUCTIONS_API_URL}/${companySlug}/${auctionId}/terms`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getAuctionResults = (auctionId, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_AUCTIONS_API_URL}/results/${auctionId}`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getFlexibleTeaserBoxes = lang => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_GLOBAL_API_URL}/teaserboxes`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getAuctionCalendar = lang => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_GLOBAL_API_URL}/calendar`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getMyProfile = lang => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/my-profile`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getBuyNowItems = lang => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_BUY_NOW_API_URL}/box`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getBuyNowCompanyItems = (companySlug, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_BUY_NOW_API_URL}/company/${companySlug}`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getBuyNowItem = (itemId, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_BUY_NOW_API_URL}/item/${itemId}`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const postBuyNowPurchaseIntent = (data, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'POST',
            url: `${process.env.VUE_APP_BUY_NOW_API_URL}/buy-now`,
            params: { lang },
            data,
        };
        return once(config, axiosInstance);
    });
};

export const getCollectionAreaTabs = lang => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_COLLECTION_AREAS_API_URL}/tabs`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getCollectionAreaLabels = (data, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_COLLECTION_AREAS_API_URL}/labels`,
            params: { ...data, lang },
        };
        return once(config, axiosInstance);
    });
};

export const getCollectionAreaContent = (areaSlug, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_COLLECTION_AREAS_API_URL}/${areaSlug}`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getBestCoinsBoxContent = lang => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_GLOBAL_API_URL}/best-coins`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getHighlights = lang => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_GLOBAL_API_URL}/highlight-page`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getHighlightBoxContent = lang => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_GLOBAL_API_URL}/highlight-box`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getHighlightSpecialBoxContent = lang => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_GLOBAL_API_URL}/highlight-box-special`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getNewsList = lang => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_GLOBAL_API_URL}/numis-news`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getSimilarLots = (lotId, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_SIMILAR_LOTS_API_URL}/${lotId}`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getWatchListIds = lang => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/my-watchlist-ids`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getNgcContent = auctionId => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'GET',
            url: `${process.env.VUE_APP_AUCTIONS_API_URL}/ngc-grading/${auctionId}.html`,
        };
        return once(config, axiosInstance);
    });
};

export const getBiddings = (data, groupByAuction, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'GET',
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/biddings`,
            params: { ...data, groupByAuction, lang },
        };
        return once(config, axiosInstance);
    });
};

export const getBiddingSheet = (sheetId, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'GET',
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/biddings/sheet/${sheetId}`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getLiveBiddingSheet = (auctionId, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'GET',
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/biddings/live/${auctionId}`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const getCountries = (lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'GET',
            url: `${process.env.VUE_APP_GLOBAL_API_URL}/countries`,
            params: { lang, list: 'all' }
        };
        return once(config, axiosInstance);
    });
};

// SIMPLE GET

export const getCurrencyRates = () => {
    return axios.get(

        `${process.env.VUE_APP_GLOBAL_API_URL}/currency-rates?lang=${store.getters['lang/getLang']}`
    );
};

export const getAds = () => {
    return axios.get(`${process.env.VUE_APP_ADS_API_URL}?lang=${store.getters['lang/getLang']}`);
};

// POST

export const postContact = (data, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'POST',
            url: `${process.env.VUE_APP_CONTACT_API_URL}/`,
            params: { lang },
            data,
        };
        return once(config, axiosInstance);
    });
};

export const postNewShippingAddress = (data, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'POST',
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/my-addresses`,
            params: { lang },
            data,
        };
        return once(config, axiosInstance);
    });
};

export const postBidSheet = (data, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'POST',
            url: `${process.env.VUE_APP_BID_SHEET_API_URL}/save-sheet`,
            params: { lang },
            data,
        };
        return once(config, axiosInstance);
    });
};

export const postLotToWatchlist = data => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'POST',
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/my-watchlist`,
            data,
        };
        return once(config, axiosInstance);
    });
};

export const deleteLotFromWatchlist = data => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'DELETE',
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/my-watchlist/${data.lotId}`,
        };
        return once(config, axiosInstance);
    });
};

export const postSearchToSearches = data => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'POST',
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/my-searches`,
            data,
        };
        return once(config, axiosInstance);
    });
};

export const deleteSearchFromSearches = searchId => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'DELETE',
            url: `${process.env.VUE_APP_USER_PRIVATE_API_URL}/my-searches/${searchId}`,
        };
        return once(config, axiosInstance);
    });
};

export const userRegister = (data, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'POST',
            url: `${process.env.VUE_APP_USER_API_URL}/register`,
            params: { lang },
            data,
        };
        return once(config, axiosInstance);
    });
};

export const userRegisterConfirm = (data, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'GET',
            url: `${process.env.VUE_APP_USER_API_URL}/confirm-registration/${data.token}`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const changeUserdataConfirm = (data, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'POST',
            url: `${process.env.VUE_APP_USER_API_URL}/confirm-userdata-changes/${data.token}`,
            params: { lang },
        };
        return once(config, axiosInstance);
    });
};

export const registerNewsletter = (email, lang) => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'POST',
            url: `${process.env.VUE_APP_NEWSLETTER_REG_URL}`,
            data: { email, lang },
        };
        return once(config, axiosInstance);
    });
};

export const getTranslation = async () => {
    return Auth.createAxiosClient().then(axiosInstance => {
        const config = {
            ...defaults,
            method: 'GET',
            url: `${process.env.VUE_APP_GLOBAL_API_URL}/frontend?file=messages.json`,
        };
        return once(config, axiosInstance);
    });

}