<template>
    <div class="buy-now-list-component">
        <!-- Buy Now list -->
        <div class="buy-now-list">
            <carousel v-if="buyNowItems.length > 0" :autoPlayInterval="8000">
                <div class="slides">
                    <slide
                        v-for="buyNowItem in buyNowItems"
                        :key="buyNowItem.itemId"
                    >
                        <buy-now-list-item
                            :buyNowItem="buyNowItem"
                        ></buy-now-list-item>
                    </slide>
                </div>
            </carousel>
        </div>
    </div>
</template>

<script>
import BuyNowListItem from './BuyNowListItem.vue';
import Carousel from './Carousel.vue';
import Slide from './Slide.vue';

export default {
    components: {
        BuyNowListItem,
        Carousel,
        Slide,
    },
    props: {
        buyNowItems: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped>
.buy-now-list-component {
    margin: 1em 0;
}
.buy-now-list {
    position: relative;
    min-height: 50px; /* for the loader */
    background: white;
    overflow: hidden;
}
.slides {
    padding: 10px 0;
}
</style>
